import { EditorScriptFlowAPI, FlowEditorSDK, FlowPlatformOptions } from '@wix/yoshi-flow-editor';
import { COLLECTION_PAGE_ID, COLLECTION_WIDGET_ID, REVIEWS_APP_ID } from '~/app-ids';
import { addTPAInternalPage, getFirstSectionRef, isResponsiveEditor } from '~/editor/page-utils';

const HEADER_GAP = 26;

export const installCollectionPage = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  options: FlowPlatformOptions,
) => {
  const t = flowAPI?.translations.t.bind(flowAPI?.translations);
  const pageRef = await addTPAInternalPage(editorSDK, {
    title: 'Collection Page',
    pageId: COLLECTION_PAGE_ID,
  });
  const headerCompRef = await editorSDK.document.components.addAndAdjustLayout('', {
    componentDefinition: {
      type: 'Component',
      componentType: 'wysiwyg.viewer.components.WRichText',
      data: {
        type: 'StyledText',
        text: `<h2 class="font_2">${t?.(
          'collection-page-header.line-1',
        )}</h2>\n\n<p class="font_8">${t?.('collection-page-header.line-2')}</p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      },
      layout: { width: 980 },
      layouts: isResponsiveEditor(options)
        ? {
            type: 'SingleLayoutData',
            componentLayout: {
              type: 'ComponentLayout',
              width: {
                type: 'px',
                value: 980,
              },
              height: {
                type: 'auto',
              },
            },
            itemLayout: {
              id: undefined as any,
              type: 'GridItemLayout',
              gridArea: {
                rowStart: 1,
                rowEnd: 2,
                columnStart: 1,
                columnEnd: 2,
              },
              margins: {
                left: {
                  type: 'spx',
                  value: 0,
                },
                right: {
                  type: 'spx',
                  value: 0,
                },
                top: {
                  type: 'spx',
                  value: 0,
                },
                bottom: {
                  type: 'spx',
                  value: 0,
                },
              },
              justifySelf: 'center',
              alignSelf: 'start',
            },
          }
        : undefined,
    },
    pageRef: isResponsiveEditor(options)
      ? await getFirstSectionRef(editorSDK, pageRef, options)
      : pageRef,
  });

  const headerLayout = await editorSDK.components.layout.get('', { componentRef: headerCompRef });
  const { compId: collectionWidgetCompId } = await editorSDK.document.tpa.add.component('', {
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: REVIEWS_APP_ID,
    widget: {
      widgetId: COLLECTION_WIDGET_ID,
      wixPageId: pageRef.id,
      shouldNavigate: false,
    },
  });
  const collectionWidgetCompRef = await editorSDK.document.components.getById('', {
    id: collectionWidgetCompId,
  });
  await editorSDK.components.layout.update('', {
    componentRef: collectionWidgetCompRef,
    layout: { y: headerLayout.height + HEADER_GAP },
  });
  if (isResponsiveEditor(options)) {
    const responsiveLayout = await editorSDK.document.responsiveLayout.get('', {
      componentRef: collectionWidgetCompRef,
    });
    await editorSDK.responsiveLayout.update('', {
      componentRef: collectionWidgetCompRef,
      responsiveLayout: {
        ...responsiveLayout,
        componentLayouts: [
          { ...responsiveLayout.componentLayouts[0], width: { type: 'px', value: 980 } },
        ],
        itemLayouts: [
          {
            ...responsiveLayout.itemLayouts[0],
            justifySelf: 'center',
            margins: {
              ...responsiveLayout.itemLayouts[0].margins,
              top: {
                type: 'px',
                value: headerLayout.height + HEADER_GAP,
              },
            },
          },
        ],
      },
    });
  }
  return pageRef;
};
